<template>
  <div class="body">
    <div class="jk_card" v-if="healthsCardListData.length > 0">
      <div class="card-face-container">
        <div class="card-detail-info">
          <img
            src="@/assets/img/home/电子健康卡-绿.png"
            class="addCard"
            alt=""
          />
          <span class="addCard-content" @click="addCard">添加就诊人</span>
          <div class="card-user-info">
            <div class="card-user-name">
              <span
                style="margin-right: 5px; font-weight: 520; color: #5ca998"
                >{{ chooseItem.name }}</span
              >
              <span
                style="font-size: 0.35rem"
                @click="toChange_jzr(currentJzrIndex)"
                >切换就诊人
                <img
                  src="@/assets/img/home/双箭头右.png"
                  style="width: 0.35rem; vertical-align: text-top"
                  alt=""
              /></span>
            </div>
            <div class="card-user-id">
              {{ chooseItem.patId }}
            </div>
          </div>
          <div class="card-barcode-info" @click="handleScanner">
            <canvas id="barcode"></canvas>
            <span class="card-barcode-tips">扫码请点击放大</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="visitsCardListData.length > 0">
        <div v-for="(item, index) in visitsCardListData" :key="index">
          <div
            class="jiuzhen-card-container"
            @click="toShowAllCard_jkk()"
            v-if="index < 1"
          >
            <div class="jz-card_a">
              <div class="uni-text">河南第二人民医院</div>
              <div class="jz_title-r" style="font-size: 15px">
                {{ visitsCardListData[0].cardTypeName }}
              </div>
            </div>
            <div class="jz-card_b">
              <div class="fl">
                <div class="text-black" style="font-size: 0.4rem">
                  {{ visitsCardListData[0].realName }}
                </div>
                <div class="text-black" style="font-size: 0.4rem">
                  {{
                    visitsCardListData[0].idCard.replace(
                      /^(.{4})(?:\d+)(.{4})$/,
                      "$1**********$2"
                    )
                  }}
                </div>
              </div>
              <div class="fr">
                <div class="jz_card_header">
                  <div class="card-qrcode">
                    <img
                      :src="visitsCardListData[0].qrcode"
                      width="2rem"
                      height="2rem"
                    />
                  </div>
                </div>
              </div>
              <div class="nocard-tips" @click="upgrade(visitsCardListData[0])">
                升级为电子健康卡
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="jzk-unset-container" @click="toShowAllCard_jkk()">
          <img
            src="@/assets/img/home/电子健康卡-灰.png"
            class="addCard1"
            alt=""
          />
          <span class="addCard-tip"> 添加就诊人 </span>
          <span class="add-tip">暂无就诊人信息，快去添加吧</span>
        </div>
      </div>
    </div>
    <!-- <div class="xx_item_group">
      <div class="xx_item jiuzhen" @click="gotoAppoint(0)">
        <i class="card-icon"></i>
        <div class="xx_item_title">当日挂号</div>
        <div class="xx_item_desc">热门科室/少排队</div>
      </div>
      <div class="xx_item yuyue" @click="gotoAppoint(1)">
        <i class="yuyue-icon"></i>
        <div class="xx_item_title">预约挂号</div>
        <div class="xx_item_desc">热门科室/少排队</div>
      </div>
    </div> -->
    <div class="xx_item_group">
      <div class="xx_item jiuzhen" @click="gotoAppoint(0)">
        <img src="../../assets/img/home/当日挂号.png" class="icon" alt="" />
        <div class="xx_item_title">当日</div>
        <div class="xx_item_title">挂号</div>
      </div>
      <div class="xx_item yuyue" @click="gotoAppoint(1)">
        <img src="../../assets/img/home/预约挂号.png" class="icon" alt="" />
        <div class="xx_item_title">预约</div>
        <div class="xx_item_title">挂号</div>
      </div>
    </div>
    <div class="example-body">
      <div class="grid_title">门诊服务</div>
      <div class="grid-item">
        <van-grid :border="false">
          <van-grid-item @click="gotoNucleic">
            <template #text>
              <span class="text">核酸检测</span>
            </template>
            <template #icon>
              <i class="hesuanjiance"></i>
            </template>
          </van-grid-item>
          <van-grid-item @click="routerPush('/nucleicPayment')">
            <template #text>
              <span class="text">核酸缴费</span>
            </template>
            <template #icon>
              <i class="hesuanjiaofei"></i>
            </template>
          </van-grid-item>
          <van-grid-item @click="routerPush('/query')">
            <template #text>
              <span class="text">报告查询</span>
            </template>
            <template #icon>
              <img
                src="../../assets/img/ucenter/4.png"
                alt=""
                class="img-style"
              />
            </template>
          </van-grid-item>
          <van-grid-item @click="toShowAllCard_jkk()">
            <template #text>
              <span class="text">电子健康卡</span>
            </template>
            <template #icon>
              <i class="dianzijiankangka"></i>
            </template>
          </van-grid-item>
          <van-grid-item @click="routerPush('/outpatientPayment')">
            <template #text>
              <span class="text">门诊缴费</span>
            </template>
            <template #icon>
              <i class="menzhenjiaofei"></i>
            </template>
          </van-grid-item>
          <van-grid-item @click="routerPush('/appointPayments')">
            <template #text>
              <span class="text">挂号缴费</span>
            </template>
            <template #icon>
              <i class="guahaojiaofei"></i>
            </template>
          </van-grid-item>
          <van-grid-item @click="routerPush('/electronics')">
            <template #text>
              <span class="text">电子发票</span>
            </template>
            <template #icon>
              <i class="dianzifapiao"></i>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #text>
              <span class="text">预防中药</span>
            </template>
            <template #icon>
              <i class="yufangzhongyao-disabled"></i>
            </template>
          </van-grid-item>
          <!-- <van-grid-item @click="routerPush('/traditionalMedical')">
            <template #text>
              <span class="text">预防中药</span>
            </template>
            <template #icon>
              <i class="yufangzhongyao"></i>
            </template>
          </van-grid-item> -->
          <van-grid-item>
            <!-- @click="routerPush('/antigenDectection')" -->
            <template #text>
              <span class="text">抗原检测</span>
            </template>
            <template #icon>
              <i class="kangyuanjiance-disabled"></i>
            </template>
          </van-grid-item>
          <van-grid-item>
            <!-- @click="routerPush('/antigenDectectionPayment')" -->
            <template #text>
              <span class="text">抗原缴费</span>
            </template>
            <template #icon>
              <i class="kangyuanjiaofei-disabled"></i>
            </template>
          </van-grid-item>
          <!-- <van-grid-item>
            <template #text>
              <span class="text">预约挂号记录</span>
            </template>
            <template #icon>
              <i class="yuyueguahaojilu-disabled"></i>
            </template>
          </van-grid-item> -->
          <van-grid-item>
            <template #text>
              <span class="text">体检预约</span>
            </template>
            <template #icon>
              <i class="tijianyuyue-disabled"></i>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #text>
              <span class="text">在线充值</span>
            </template>
            <template #icon>
              <i class="zaixianchongzhi-disabled"></i>
            </template>
          </van-grid-item>
          <van-grid-item>
            <!-- @click="routerPush2" -->
            <template #text>
              <span class="text">电子陪护证</span>
            </template>
            <template #icon>
              <i class="dianzipeihuzheng-disabled"></i>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #text>
              <span class="text">新生儿游泳</span>
            </template>
            <template #icon>
              <i class="xinshengeryouyong-disabled"></i>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #text>
              <span class="text">病案复印</span>
            </template>
            <template #icon>
              <i class="binganfuyin-disabled"></i>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #text>
              <span class="text">高校医务室</span>
            </template>
            <template #icon>
              <i class="gaoxiaoyiwushi-disabled"></i>
            </template>
          </van-grid-item>
          <!-- <van-grid-item>
            <template #text>
              <span class="text">住院充值记录</span>
            </template>
            <template #icon>
              <i class="zhuyuanchongzhi-disabled"></i>
            </template>
          </van-grid-item> -->
          <!-- <van-grid-item to="/neonatalInfoConfirm">
            <template #text>
              <span class="text">新生儿游泳</span>
            </template>
            <template #icon>
              <img src="../../assets/img/home/youyong.png" alt="" class="img-style"/>
            </template>
          </van-grid-item> -->
        </van-grid>
      </div>
    </div>
    <div class="example-body">
      <div class="grid_title">住院服务</div>
      <div class="grid-item">
        <van-grid :border="false">
          <van-grid-item to="/advancePayment">
            <template #text>
              <span class="text">预交金</span>
            </template>
            <template #icon>
              <i class="yujiaojin"></i>
            </template>
          </van-grid-item>
          <van-grid-item to="/dailyChecklist">
            <template #text>
              <span class="text">每日清单</span>
            </template>
            <template #icon>
              <i class="meiriqingdan"></i>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #text>
              <span class="text">入院登记</span>
            </template>
            <template #icon>
              <i class="ruyuandengji-disabled"></i>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #text>
              <span class="text">出院办理</span>
            </template>
            <template #icon>
              <i class="chuyuanbanli-disabled"></i>
            </template>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <van-popup
      round
      v-model="barShowState"
      v-if="barShowState"
      :close-on-click-overlay="false"
    >
      <div class="barcode-body">
        <div class="tab-title">
          <span :class="{ active: active == 0 }" @click="active = 0"
            >院内条形码</span
          >
          <span :class="{ active: active == 1 }" @click="active = 1"
            >电子健康码</span
          >
          <div v-show="active == 0">
            <div class="barcode-name">{{ chooseItem.name }}</div>
            <canvas id="canvas"></canvas>
            <div class="patIdBox">{{ chooseItem.patId }}</div>
          </div>
          <div v-show="active == 1">
            <div class="barcode-name">{{ chooseItem.name }}</div>
            <div class="barcode-name">电子健康卡静态码</div>
            <div class="qrcanvas">
              <div
                ref="qrcanvas"
                style="
                  width: 156px;
                  height: 156px;
                  margin: auto;
                  position: relative;
                "
              >
                <div class="QRlogo positionCenter">
                  <img src="~@/assets/img_health/logo_.png" />
                </div>
              </div>
            </div>
          </div>
          <van-button block class="button-close" @click="handleClose"
            >关闭</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-popup
      round
      v-model="jzrShowState"
      v-if="jzrShowState"
      :close-on-click-overlay="false"
    >
      <div class="jzr_body">
        <div class="change_jzr">切换就诊人</div>
        <div
          class="yyqr-choose-jzk"
          v-for="(item, index) in healthsCardListData"
          :key="index"
          @click="chooseJZPerson(item, index)"
        >
          <span v-if="index == tmpCurrentJzrIndex" class="checked">
            <img src="@/assets/img/checked.png" alt="" />
            {{ item.name + "(" + item.idNumber + ")" }}</span
          >
          <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
        </div>
        <div class="jzr_btn_close">
          <van-button block @click="confirmJzr">确定</van-button>
          <van-button block style="color: #666" @click="jzrShowState = false"
            >关闭</van-button
          >
        </div>
      </div>
    </van-popup>
    <footer>
      <van-tabbar v-model="active" placeholder active-color="#3CDEA6">
        <van-tabbar-item to="/treatmentServices">
          <template #icon="props">
            <i
              :class="props.active ? 'zhenliaofuwu-active' : 'zhenliaofuwu'"
            ></i>
          </template>
          <span class="text">诊疗服务</span>
        </van-tabbar-item>
        <van-tabbar-item to="/medicalGuide">
          <template #icon="props">
            <i :class="props.active ? 'jiuyizhinan-active' : 'jiuyizhinan'"></i>
          </template>
          <span class="text">就医指南</span>
        </van-tabbar-item>
        <van-tabbar-item to="/personalCenter">
          <template #icon="props">
            <i
              :class="props.active ? 'gerenzhongxin-active' : 'gerenzhongxin'"
            ></i>
          </template>
          <span class="text">个人中心</span>
        </van-tabbar-item>
      </van-tabbar>
    </footer>
    <van-notify v-model="notifyShow">
      <img src="@/assets/img/chenggong2.png" class="chenggong2" alt="" />
      <span>升级为电子健康卡成功</span>
    </van-notify>
    <van-dialog
      class="dialog"
      v-model="overflowShow"
      title="温馨提示"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <div class="dialog-message">
        <p>
          {{ content }}
        </p>
      </div>
      <van-button
        type="primary"
        class="confirm-btn"
        @click="overflowShow = false"
        >确认</van-button
      >
    </van-dialog>
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import QRCode from "qrcodejs2";

export default {
  name: "treatmentServices",
  data() {
    return {
      healthsCardListlength: 0,
      healthsCardListData: [],
      visitsCardListData: [],
      visitsCardListlength: 0,
      JzPersonlist: [],
      active: 0,
      chooseItem: {},
      tmpChooseItem: {},
      qrCodeText: "",
      qrImgSrc: require("@/assets/img_health/qrcode.png"),
      currentJzrIndex: 0,
      tmpCurrentJzrIndex: 0,
      code: "",
      showAllCardNum: 1,
      notifyShow: false,
      barShowState: false,
      jzrShowState: false,

      content: "",
      overflowShow: false,
      nucleicState: false,
      dialogPopCount: 0,
    };
  },
  mounted() {
    this.getAliPayCode();
    let CurrentJZPerson = JSON.parse(sessionStorage.getItem("CurrentJZPerson"));
    if (CurrentJZPerson) {
      this.chooseItem = CurrentJZPerson;
    } else {
      this.chooseItem = this.healthsCardListData[this.currentJzrIndex];
    }
  },
  methods: {
    gotoNucleic() {
      this.getState();
    },
    /**
     * 获取核酸检测功能开放状态
     */
    getState() {
      this.$http
        .get("/api/search/state", {
          type: 0, //this.$route.query.personType,
        })
        .then((result) => {
          this.overflowShow = !result.data.state;
          this.nucleicState = !result.data.state;
          if (!this.nucleicState) {
            this.$router.push("/enterPromise?personType=0");
          }
          this.content = result.data.content;
          return;
        });
    },
    /**
     * 获取微信公众平台的code
     */
    getAliPayCode() {
      // this.$store.commit("showLoading");
      // 判断是否有微信code
      let sessionCode = sessionStorage.getItem("AliPayCode");
      if (!sessionCode) {
        // 没有则进行授权
        const redirectUrl = `${domain}index.html%23%2FtreatmentServices`;
        this.code = this.getUrlCode().code; // 截取auth_code用于获取openid
        if (this.code == null || this.code === "" || this.code == undefined) {
          // 如果没有code，则去请求
          window.location.replace(
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
          );
        } else {
          sessionStorage.setItem("AliPayCode", this.code);
          // 获取openid
          this.getOpenID(this.code);
        }
      } else {
        this.getOpenID(sessionCode);
      }
    },
    /**
     * 根据code获取openid
     */
    getOpenID(code) {
      // 判断是否有openid
      if (sessionStorage.getItem("openid")) {
        this.getCardListData();
        return;
      }
      let postData = {
        code: code,
      };
      this.$http
        .post("/api/login/wx", postData)
        .then((res) => {
          sessionStorage.setItem("user", JSON.stringify(res.data));
          sessionStorage.setItem("userid", res.data.id);
          sessionStorage.setItem("openid", res.data.userId);
          this.getCardListData();
          this.$store.commit("hideLoading");
        })
        .catch((err) => {
          this.$store.commit("hideLoading");
          this.$toast.fail("发生错误，请关闭页面之后重新授权...");
          console.log(err);
          // 发生错误说明可能是code过期，或者session丢失，重置session重新鉴权
          sessionStorage.setItem("AliPayCode", "");
          sessionStorage.setItem("userid", "");
          sessionStorage.setItem("openid", "");
          // let redirectUrl = `${domain}index.html%23%2FtreatmentServices`;
          // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`;
        });
    },
    // 获取url中code
    getUrlCode() {
      let url = location.href;
      var s = url.indexOf("?");
      var t = url.substring(s + 1);
      let strs = t.split("&");
      let theRequest = new Object();
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
      return theRequest;
    },
    //获取登录用户信息获取名下绑定的健康卡列表
    getCardListData() {
      var user = JSON.parse(sessionStorage.getItem("user"));
      this.healthsCardListlength = 0;
      this.healthsCardListData = [];
      this.visitsCardListlength = 0;
      this.visitsCardListData = [];
      let postData = {
        userid: user.userId,
      };
      this.$http
        .get("/api/search/cards", postData)
        .then((res) => {
          if (res.data && res.data.healths.length > 0) {
            this.healthsCardListData = res.data.healths;
            this.healthsCardListlength = res.data.healths.length;
            window.localStorage.setItem(
              "healthsCardListData",
              JSON.stringify(res.data.healths)
            );
            this.$nextTick(() => {
              this.barCodeInit();
            });
          }
          if (res.data && res.data.visits.length > 0) {
            this.visitsCardListData = res.data.visits;
            this.visitsCardListlength = res.data.visits.length;
            window.localStorage.setItem(
              "visitsCardListData",
              JSON.stringify(res.data.visits)
            );
          }
          this.currentJzrIndex = sessionStorage.getItem("CurrentJzrIndex")
            ? sessionStorage.getItem("CurrentJzrIndex")
            : 0;
          this.chooseItem = this.healthsCardListData[this.currentJzrIndex];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 升级电子健康卡
    upgrade(data) {
      sessionStorage.setItem("visitsCard", JSON.stringify(data));
      this.$router.push({ path: "/upgrade" });
    },
    //是否展示全部健康卡
    toShowAllCard_jkk() {
      this.$router.push({ path: "/healthCard" });
    },
    routerPush(path) {
      this.$router.push({
        path: path,
        query: {
          activeName: "a",
        },
      });
      if (path == "/query") {
        this.$store.commit("setQueryActiveName", "");
      }
    },
    routerPush2() {
      window.location.href = `https://h5-health.tengmed.com/h5/report/certificate/escort?hospitalId=32912&cityCode=410184`;
    },
    toAppointDept() {
      this.$router.push("/dept?type=1");
      this.$store.commit("setDeptActiveIndex", 0);
    },
    addCard() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("addCardOne");
    },
    /**
     * 开启放大查看条形码
     */
    handleScanner() {
      this.barShowState = true;
      this.active = 0;
      this.barCodeInit2();
    },
    gotoAppoint(type) {
      this.$store.commit("setShowDeptTips", true);
      this.$store.commit("setDeptActiveIndex", 0);
      this.$router.push({
        path: "/dept",
        query: {
          type: type,
        },
      });
    },
    /**
     * 放大查看条形码
     */
    barCodeInit2() {
      this.$nextTick(() => {
        console.log(this.chooseItem);
        JsBarcode("#canvas", this.chooseItem.patId, {
          lineColor: "#000", //线条颜色
          background: "transparent",
          width: 10, //线宽
          height: 30, //条码高度
          displayValue: false, //是否显示文字信息
        });
        new QRCode(this.$refs.qrcanvas, {
          width: 156,
          height: 156,
          colorDark: "#333333", // 二维码颜色
          colorLight: "#ffffff", // 二维码背景色
          text: this.chooseItem.healthId, // 二维码的内容
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    /**
     * 关闭放大查看条形码功能
     */
    handleClose() {
      this.barShowState = false;
    },
    /**
     * 生成条形码
     */
    barCodeInit() {
      JsBarcode(
        "#barcode",
        this.healthsCardListData[this.currentJzrIndex].patId,
        {
          lineColor: "#000", //线条颜色
          background: "transparent",
          width: 10, //线宽
          height: 30, //条码高度
          displayValue: false, //是否显示文字信息
        }
      );
    },
    /**
     * 切换就诊人
     */
    toChange_jzr(index) {
      this.jzrShowState = true;
      this.tmpCurrentJzrIndex = index;
    },
    /**
     * 选择就诊人
     */
    chooseJZPerson(item, index) {
      this.tmpChooseItem = item;
      this.tmpCurrentJzrIndex = index;
    },
    confirmJzr() {
      this.jzrShowState = false;
      this.currentJzrIndex = this.tmpCurrentJzrIndex;
      this.chooseItem = this.healthsCardListData[this.currentJzrIndex];
      this.barCodeInit();
      sessionStorage.setItem(
        "CurrentJZPerson",
        JSON.stringify(this.chooseItem)
      );
      sessionStorage.setItem("CurrentJzrIndex", this.currentJzrIndex);
    },
  },
};
</script>

<style scoped>
.body {
  padding-top: 0.001rem;
  height: 2.5rem;
  background-color: green;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
  background-image: linear-gradient(#25e5a3, #10d994);
}

.jiuzhen-card-container {
  display: flex;
  height: 5rem;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  width: 9rem;
  margin: 15px auto;
  background: url(../../assets/img/jiuzhenka.png);
  background-size: 100%;
  padding: 15px;
  justify-content: space-between;
  border-radius: 10px;
}

.jzk-unset-container {
  display: flex;
  height: 2.5rem;
  margin: 0.46rem;
  margin-bottom: 0.4rem;
  position: relative;
  align-items: flex-end;
  flex-direction: column-reverse;
  border-radius: 10px;
  overflow: hidden;
  background-image: linear-gradient(#fefefe, #c5f5eb);
}

.uni-text {
  font-size: 0.4rem;
}

.text-black {
  color: #333333;
  text-align: left;
}

.jz-card_a {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 50px;
}

.jz-card_a .uni-text {
  float: left;
}

.jz_title-r {
  float: right;
  margin-right: 10px;
}

.jz-card_b {
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
  /* margin-right: 10px; */
}

.jz-card_b .fl {
  position: relative;
  top: 20px;
}

.jz_card_header {
  float: right;
  width: 2rem;
  height: 2rem;
  background-size: 100%;
  margin-bottom: 5px;
  background-image: url("../../assets/img/qrcode.png");
  border: 3px solid #fff;
}

.nocard-tips {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  font-size: 0.4rem;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}

.jk_card {
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  margin: 0.46rem;
  margin-bottom: 0.4rem;
  overflow: hidden;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}

.jk_card_header .card-qrcode {
  padding: 0.1rem;
  position: absolute;
  right: 1rem;
  top: -1rem;
  height: 2rem;
  width: 2rem;
  box-sizing: content-box;
}

.card-face-container {
  height: 2.5rem;
  border-radius: 10px;
  position: relative;
  color: #000000;
  background-image: linear-gradient(#fefefe, #c5f5eb);
}

.card-face-container .card-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
}

.card-face-container .card-top-info .card-top-org {
  font-size: 0.4rem;
  position: absolute;
  left: 10px;
  top: 15px;
}

.card-face-container .card-detail-info {
  position: absolute;
  top: 0.3rem;
  flex-flow: row;
  width: 100%;
  font-size: 0.4rem;
  text-align: left;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.card-face-container .card-detail-info .card-barcode-info {
  font-size: 0.3rem;
  margin-right: 5px;
  text-align: center;
}
.card-face-container .card-detail-info .card-user-info {
  margin-top: 0.2rem;
  margin-right: 0.15rem;
}

.card-face-container .card-detail-info .card-user-name {
  margin-bottom: 10px;
  font-size: 0.43rem;
}

.card-face-container .card-detail-info .card-qrcode {
  padding: 0.1rem;
  position: absolute;
  right: 0.5rem;
  top: -1rem;
  height: 2rem;
  width: 2rem;
  box-sizing: content-box;
}

.card-face-container .card-detail-info .card-qrcode img {
  height: 2rem;
  width: 2rem;
}

.xx_item_group {
  width: 9rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.xx_item {
  display: inline-block;
  border: none;
  width: 4.5rem;
  height: 2.5rem;
  margin: 0 0.1rem;
  border-radius: 8px;
  position: relative;
  color: #fff;
}

/* .xx_item .xx_item_title {
  margin-top: 0.6rem;
  font-size: 0.4rem;
  text-align: left;
  width: 60%;
  float: right;
} */
.xx_item .xx_item_title {
  margin-top: 0.35rem;
  font-size: 0.5rem;
  font-weight: bold;
  text-align: center;
  width: 60%;
  float: right;
}

.xx_item .xx_item_desc {
  font-size: 0.3rem;
  text-align: left;
  width: 60%;
  margin-top: 0.2rem;
  float: right;
}

.yuyue {
  background-color: #e2f7f3;
  color: #51a492;
  /* background-image: url("../../assets/img/home/yuyue-bg.png");
  background-size: 100% 100%; */
}

.jiuzhen {
  background-color: #fef3dc;
  color: #e4925d;
  /* background-image: url("../../assets/img/home/jiuzhen-bg.png");
  background-size: 100% 100%; */
}

.example-body {
  display: block;
  margin-top: 0.4rem;
}

.grid_title {
  text-align: left;
  margin-left: 0.3rem;
  height: 1.1rem;
  font-size: 0.44rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 0.76rem;
  color: #333333;
  opacity: 1;
}

.grid_title:before {
  content: "";
  width: 0.09rem;
  height: 0.44rem;
  background: #00d48b;
  opacity: 1;
  border-radius: 1rem;
  display: inline-block;
  margin: 0 10px;
  position: relative;
  top: 2px;
}

.grid-item {
  width: 9rem;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  overflow: hidden;
  margin-bottom: 0.2rem;
}

.text {
  font-size: 0.3rem;
}

/deep/ .van-tabbar {
  height: 1.3rem;
}

.addCard {
  height: 1.5rem;
  width: 1.7rem;
  position: absolute;
  left: 10px;
  top: -10%;
}
.dialog-message {
  font-size: 0.45rem;
  line-height: 26px;
  margin-bottom: 5px;
  max-height: 40vh;
  overflow: auto;
  text-align: left;
  padding: 0.5rem 0.8rem;
}
.addCard-content {
  position: absolute;
  left: 10px;
  bottom: -5%;
  font-size: 0.35rem;
  color: #333333;
}
.addCard1 {
  height: 1.8rem;
  width: 2rem;
  position: absolute;
  left: 10px;
  top: 15%;
}

.addCard-tip {
  width: 3.11rem;
  font-size: 0.44rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  opacity: 1;
  position: absolute;
  left: 45%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.add-tip {
  width: 5rem;
  position: absolute;
  left: 61%;
  top: 65%;
  text-align: left;
  color: #999;
  transform: translate(-50%, -50%);
}

/deep/ .van-grid-item__content {
  height: auto;
}

/deep/ .van-notify {
  background-color: rgba(0, 0, 0, 0.5);
}

.chenggong2 {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.img-style {
  height: 0.8rem;
  width: 0.8rem;
  display: block;
  margin-bottom: 0.2rem;
}

#barcode {
  width: 3rem;
  height: 1.2rem;
  display: flex;
}

.barcode-body {
  width: 9rem;
  height: 320px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  overflow: hidden;
}

.tab-title > span {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-size: 0.4rem;
  margin: 0 15px;
}

.active {
  color: #1be09c;
}

.active:before {
  content: "";
  width: 30px;
  height: 3px;
  background-color: #1be09c;
  display: block;
  position: relative;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
}
.change_jzr {
  color: #1be09c;
  height: 50px;
  line-height: 50px;
  font-size: 0.4rem;
  width: 100%;
}

#canvas {
  width: 7rem;
  height: 2.5rem;
}

.patIdBox {
  font-size: 0.4rem;
  margin-bottom: 0.4rem;
}

.button-close {
  border: none;
  color: #1be09c;
  position: absolute;
  bottom: 0;
}

.qrcanvas {
  width: 100%;
  height: auto;
}

.QRlogo {
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
}
.card-user-id {
  color: #999;
}
.card-barcode-tips {
  color: #999;
}
.barcode-name {
  font-size: 0.45rem;
  font-weight: 550;
  margin-bottom: 0.1rem;
}
.jzr_body {
  width: 9rem;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  overflow: hidden;
  padding-bottom: 44px;
}
.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
.yyqr-choose-jzk .checked {
  color: #03d28c;
}
.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}
.jzr_btn_close {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.jzr_btn_close .van-button {
  border: none;
  color: #1be09c;
  width: 50%;
}
/deep/ .van-cell__value {
  text-align: center;
}
.icon {
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  left: 0.2rem;
  top: 50%;
  display: inline-block;
  transform: translateY(-50%);
}
.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
</style>
